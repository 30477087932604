<template>
  <div class="monitor-screen">
    <div class="header" v-if="!viewCompany">
      <span class="header-title">中材国际智慧安全监控大屏</span>
    </div>
    <div class="content-container">
      <div v-if="!viewCompany" class="left-container">
        <div class="earth-content">
          <div class="earth-content-title">
            <img src="../../assets/largeScreen/earth-top-title.png" alt="" />
            <p>
              国外项目/工厂<span>{{
      superPageData["projectsInsideAndOutside"] && superPageData["projectsInsideAndOutside"]["国外项目"]
    }}</span>个
            </p>
          </div>
          <div class="earth-content-ball">
            <img src="../../assets/largeScreen/earth-ball.png" alt="地球" />
            <span class="state ns">北美洲{{ superPageData.projectsOutside && superPageData.projectsOutside["北美洲"]
              }}个</span>
            <span class="state as">亚洲{{ superPageData.projectsOutside && superPageData.projectsOutside["亚洲"] }}个</span>
            <span class="state eu">欧洲{{ superPageData.projectsOutside && superPageData.projectsOutside["欧洲"] }}个</span>
            <span class="state af">非洲{{ superPageData.projectsOutside && superPageData.projectsOutside["非洲"] }}个</span>
            <span class="state oc">大洋洲{{ superPageData.projectsOutside && superPageData.projectsOutside["大洋洲"]
              }}个</span>
            <span class="state sa">南美洲{{ superPageData.projectsOutside && superPageData.projectsOutside["南美洲"]
              }}个</span>
          </div>
          <div class="earth-content-desc">
            <div class="earth-content-desc-container">
              <div>
                <p class="square as-bg">
                  亚洲区域 <span>{{ superPageData.projectsOutside && superPageData.projectsOutside["亚洲"] }}</span> 个
                </p>
                <p class="square af-bg">
                  非洲区域 <span>{{ superPageData.projectsOutside && superPageData.projectsOutside["非洲"] }}</span> 个
                </p>
                <p class="square eu-bg">
                  欧洲区域 <span>{{ superPageData.projectsOutside && superPageData.projectsOutside["欧洲"] }}</span> 个
                </p>
              </div>
              <div>
                <p class="square sa-bg">
                  南美洲区域
                  <span>{{ superPageData.projectsOutside && superPageData.projectsOutside["南美洲"] }}</span> 个
                </p>
                <p class="square na-bg">
                  北美洲区域
                  <span>{{ superPageData.projectsOutside && superPageData.projectsOutside["北美洲"] }}</span> 个
                </p>
                <p class="square oc-bg">
                  大洋洲区域
                  <span>{{ superPageData.projectsOutside && superPageData.projectsOutside["大洋洲"] }}</span> 个
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!viewCompany" class="center-container">
        <div class="map-title">
          <span>监控大屏</span>
        </div>
        <div class="map-container">
          <div id="center_map" class="map-box"></div>
        </div>
        <div @click="clickItem($event)" class="company-list-container">
          <div class="company-list-item" v-for="(item, i) in scrollData" :key="i" :data-obj="JSON.stringify(item)">
            <div class="company-list-item-top" :data-obj="JSON.stringify(item)">
              <span :data-obj="JSON.stringify(item)">{{ item.count }}</span>
            </div>
            <el-tooltip :content="item.name" placement="bottom">
              <p :data-obj="JSON.stringify(item)">{{ item.name }}</p>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div v-if="!viewCompany" class="right-container">
        <div class="earth-content">
          <div class="earth-content-title">
            <img src="../../assets/largeScreen/earth-top-title.png" alt="" />
            <p>
              国内项目/工厂<span>{{
      superPageData["projectsInsideAndOutside"] && superPageData["projectsInsideAndOutside"]["国内项目"]
    }}</span>个
            </p>
          </div>
          <div class="earth-map">
            <img src="../../assets/largeScreen/earth-map.png" alt="中国地图" />
            <span class="state ns">西北{{ superPageData.projectsInside && superPageData.projectsInside["西北"] }}个</span>
            <span class="state as">华北{{ superPageData.projectsInside && superPageData.projectsInside["华北"] }}个</span>
            <span class="state eu">东北{{ superPageData.projectsInside && superPageData.projectsInside["东北"] }}个</span>
            <span class="state af">华中{{ superPageData.projectsInside && superPageData.projectsInside["华中"] }}个</span>
            <span class="state oc">华东{{ superPageData.projectsInside && superPageData.projectsInside["华东"] }}个</span>
            <span class="state ws">西南{{ superPageData.projectsInside && superPageData.projectsInside["西南"] }}个</span>
            <span class="state sa">华南{{ superPageData.projectsInside && superPageData.projectsInside["华南"] }}个</span>
          </div>
          <div class="earth-content-desc">
            <div class="earth-content-desc-container ml">
              <div>
                <p class="square as-bg">
                  西北区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["西北"] }}</span> 个
                </p>
                <p class="square af-bg">
                  东北区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["东北"] }}</span> 个
                </p>
                <p class="square eu-bg">
                  华北区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["华北"] }}</span> 个
                </p>
              </div>
              <div>
                <p class="square sa-bg">
                  华东区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["华东"] }}</span> 个
                </p>
                <p class="square na-bg">
                  华中区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["华中"] }}</span> 个
                </p>
                <p class="square oc-bg">
                  华南区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["华南"] }}</span> 个
                </p>
              </div>
              <div class="item-left">
                <p class="square ws-bg">
                  西南区域 <span>{{ superPageData.projectsInside && superPageData.projectsInside["西南"] }}</span> 个
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <project-map @backScreen="clickBackBtn" v-if="viewCompany"></project-map>
    </div>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import projectMap from "./projectMap.vue";
import { getSuperScreenData } from "@/api/projectManagement/projectManagement.js";

export default {
  name: "monitorScreen",
  data() {
    return {
      listData: [],
      superPageData: {},
      map: null,
      viewCompany: false,
      center: [40.780269, 20.955403],
      areaCoordinates: [
        { name: "非洲", lnglat: [24.420876, 0.409759], desc: "" }, // 非洲,
        { name: "北美洲", lnglat: [-115.401671, 38.646664], desc: "" }, // 北美,
        { name: "南美洲", lnglat: [-64.330775, -25.418858], desc: "" }, // 南美,
        { name: "欧洲", lnglat: [13.820942, 44.392556], desc: "" }, // 欧洲,
        { name: "亚洲", lnglat: [128.472895, 51.127711], desc: "" }, // 亚洲,
        { name: "大洋洲", lnglat: [132.005176, -16.868082], desc: "" }, // 大洋洲,
      ],
      scrollData: [],
    };
  },
  components: {
    vueSeamlessScroll,
    projectMap,
  },
  // 监听属性 类似于data概念
  computed: {
    defaultOption() {
      return {
        limitMoveNum: this.scrollData.length,
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: false, // 是否开启鼠标悬停stop
        switchSingleStep: 140,
        // autoPlay: false,
        // navigation: true,
        direction: 2, // 0向下 1向上 2向左 3向右
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    // scrollData() {
    //   let arr = [];
    //   this.superPageData.projectsCompany &&
    //     Object.keys(this.superPageData.projectsCompany).forEach((item) => {
    //       if (this.superPageData.projectsCompany[item].name) {
    //         arr.push({
    //           id: item,
    //           name: this.superPageData.projectsCompany[item].name,
    //           count: this.superPageData.projectsCompany[item].count,
    //         });
    //       }
    //     });
    //   return arr;
    // },
  },
  mounted() {
    this.initPage();

  },
  created() {
    this.$title = "中材国际智慧安全监控大屏";
    document.title = "中材国际智慧安全监控大屏";
  },
  methods: {
    clickBackBtn() {
      this.viewCompany = false;
      this.$nextTick(() => {
        this.initPage();
      });
    },
    async initPage() {
      let resData = await getSuperScreenData();
      this.superPageData = resData.data.data;
      let arr = [];
      // this.superPageData.projectsCompany &&
      //   Object.keys(this.superPageData.projectsCompany).forEach((item) => {
      //     arr.push({
      //       id: item,
      //       name: this.superPageData.projectsCompany[item].name,
      //       count: this.superPageData.projectsCompany[item].count,
      //     });
      //   });
      this.superPageData.projectsCompany.forEach((item) => {
        for (let key in item) {
          arr.push({
            id: key,
            name: item[key].name,
            count: item[key].count,
          });
        }
      });
      this.scrollData = arr;
      this.listData = JSON.parse(JSON.stringify(arr));
      if (this.map) {
        this.map.destroy();
      }
      this.map = new AMap.Map("center_map", {
        center: this.center,
        mapStyle: "amap://styles/503006feca46ab82417777eada0c01ae",
        animateEnable: true,
        zoom: 3.2,
        viewMode: "3D",
        pitch: 18,
      });
      let loca = new Loca.Container({
        map: this.map,
      });
      // 呼吸点
      let scatter = new Loca.ScatterLayer({
        loca,
        zIndex: 10,
        opacity: 0.6,
        visible: true,
        zooms: [2, 10],
      });
      let pointGeo = new Loca.GeoJSONSource({
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "Point",
                coordinates: [116.405285, 39.904989],
              },
            },
          ],
        },
      });
      scatter.setSource(pointGeo);
      scatter.setStyle({
        unit: "meter",
        size: [1000000, 1000000],
        borderWidth: 0,
        texture: "https://a.amap.com/Loca/static/loca-v2/demos/images/breath_yellow.png",
        // duration: 2000,
        animate: true,
      });
      loca.add(scatter);
      // 弧线
      let pulseLink = new Loca.PulseLinkLayer({
        zIndex: 10,
        opacity: 1,
        visible: true,
        zooms: [2, 22],
        depth: true,
      });
      let geo = new Loca.GeoJSONSource({
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [116.405285, 39.904989],
                  [24.420876, 0.409759], // 非洲
                ],
              },
            },
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [116.405285, 39.904989],
                  [-115.401671, 38.646664], // 北美
                ],
              },
            },
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [116.405285, 39.904989],
                  [-64.330775, -25.418858], // 南美
                ],
              },
            },
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [116.405285, 39.904989],
                  [13.820942, 44.392556], // 欧洲
                ],
              },
            },
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [116.405285, 39.904989],
                  [128.472895, 51.127711], // 亚洲
                ],
              },
            },
            {
              type: "Feature",
              properties: {
                type: 2,
                ratio: 0.0189,
                lineWidthRatio: 0.47674418604651164,
              },
              geometry: {
                type: "LineString",
                coordinates: [
                  [116.405285, 39.904989],
                  [132.005176, -16.868082], // 大洋洲
                ],
              },
            },
          ],
        },
      });
      pulseLink.setSource(geo);
      pulseLink.setStyle({
        unit: "meter",
        dash: [40000, 0, 40000, 0],
        lineWidth: function () {
          return [40000, 2000];
        },
        height: function (index, feat) {
          return feat.distance / 3 + 10;
        },
        smoothSteps: 30,
        speed: function () {
          return 2000000;
        },
        flowLength: 3000000,
        lineColors: function () {
          return ["#EB8F15", "#987A4F", "#977A50"];
        },
        maxHeightScale: 0.3, // 弧顶位置比例
      });
      loca.add(pulseLink);
      loca.animate.start();
      // 地图打多个marker点
      let makerList = [];
      this.areaCoordinates.forEach((areaCoordinate) => {
        let marker = new AMap.Marker({
          title: areaCoordinate.name,
          size: new AMap.Size(21, 45), // 图标尺寸
          position: new AMap.LngLat(areaCoordinate.lnglat[0], areaCoordinate.lnglat[1]),
          offset: new AMap.Pixel(-10, -21),
          icon: require("../../assets/largeScreen/map-marker.png"),
        });
        marker.setLabel({
          direction: areaCoordinate.name === "亚洲" ? "right" : "bottom",
          offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
          content: `<div class="marker-info">
            <h5>${areaCoordinate.name}区域 ${this.superPageData.projectsOutside[areaCoordinate.name]} 个</h5>
            <img src=${require("../../assets/largeScreen/fake-img.png")} alt="水泥厂">
            </div>`, //设置文本标注内容
        });
        makerList.push(marker);
      });
      // 放入地图中
      this.map.add(makerList);
    },
    clickItem(e) {
      let item = JSON.parse(e.target.dataset.obj);
      sessionStorage.setItem("parentId", item.id);
      this.viewCompany = true;
    },
  },
};
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.monitor-screen {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/largeScreen/large-screen-bg.png");
  background-size: contain;
  position: relative;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    height: vh(153);
    background: url("../../assets/largeScreen/header-bg.png") no-repeat 0 36px;
    background-size: contain;
  }

  .header-title {
    letter-spacing: vw(2);
    font-size: font(52);
    background-image: linear-gradient(92deg, #0072ff 0%, #00eaff 48.8525390625%, #01aaff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    padding-top: 32px;
  }

  .content-container {
    position: absolute;
    top: 0;
    display: flex;

    .left-container {
      width: vw(993);
      height: 100vh;
      background: url("../../assets/largeScreen/left-bg.png") no-repeat;
      background-size: vw(993) vh(1290);

      .earth-content {
        display: flex;
        flex-direction: column;
        margin-top: vh(150);
        align-items: center;

        .earth-content-title {
          position: relative;

          img {
            width: vw(1000);
            height: vh(61);
          }

          p {
            font-size: font(36);
            color: #ffffff;
            position: absolute;
            top: 0;
            left: vw(374);

            span {
              font-size: font(40);
              font-weight: 700;
              color: #00ccff;
              margin: 0 vw(10);
            }
          }
        }

        .earth-content-ball {
          position: relative;
          margin-top: vh(100);

          img {
            width: vw(682);
            height: vh(693);
          }

          span {
            position: absolute;
            font-size: font(16);
            color: #fafcfc;
            -webkit-text-stroke: 1px #f37d00;
            text-stroke: 1px #f37d00;
          }

          .ns {
            left: vw(130);
            top: vh(200);
          }

          .as {
            right: vw(160);
            top: vh(240);
          }

          .eu {
            right: vw(280);
            top: vh(280);
          }

          .af {
            right: vw(290);
            bottom: vh(280);
            font-weight: 700;
            font-size: font(24);
          }

          .oc {
            right: vw(60);
            bottom: vh(250);
          }

          .sa {
            left: vw(170);
            bottom: vh(200);
          }
        }

        .earth-content-desc {
          width: vw(750);
          margin-top: vh(40);

          .earth-content-desc-container {
            div {
              display: flex;
              justify-content: space-around;
            }

            p {
              min-width: 120px;
              width: vw(200);
              font-size: font(16);
              color: #ffffff;
              display: flex;
              align-items: center;

              span {
                font-size: font(24);
                margin: 0 vw(10);
              }

              &.square:before {
                display: inline-block;
                content: "";
                width: 17px;
                height: 17px;
                vertical-align: top;
                margin-right: vw(10);
              }

              &.as-bg:before {
                background: #1969fd;
              }

              &.af-bg:before {
                background: #61faf1;
              }

              &.eu-bg:before {
                background: #02f7b5;
              }

              &.sa-bg:before {
                background: #b43ff7;
              }

              &.na-bg:before {
                background: #1496f1;
              }

              &.oc-bg:before {
                background: #c1f73f;
              }
            }
          }
        }
      }
    }

    .right-container {
      width: vw(993);
      height: 100vh;
      background: url("../../assets/largeScreen/left-bg.png") no-repeat;
      background-size: cover;

      .earth-content {
        display: flex;
        flex-direction: column;
        margin-top: vh(150);
        align-items: center;

        .earth-content-title {
          position: relative;

          img {
            width: vw(1000);
            height: vh(61);
          }

          p {
            font-size: font(36);
            color: #ffffff;
            position: absolute;
            top: 0;
            left: vw(374);

            span {
              font-size: font(40);
              font-weight: 700;
              color: #00ccff;
              margin: 0 vw(10);
            }
          }
        }

        .earth-map {
          position: relative;
          margin-top: vh(100);

          img {
            width: vw(823);
            height: vh(700);
          }

          span {
            position: absolute;
            font-size: font(16);
            color: #fafcfc;
            -webkit-text-stroke: 1px #f37d00;
            text-stroke: 1px #f37d00;
          }

          .ns {
            left: vw(280);
            top: vh(190);
            font-size: font(24);
          }

          .as {
            right: vw(240);
            top: vh(220);
          }

          .eu {
            right: vw(100);
            top: vh(140);
          }

          .af {
            right: vw(255);
            bottom: vh(318);
          }

          .oc {
            right: vw(156);
            bottom: vh(272);
          }

          .ws {
            left: vw(450);
            bottom: vh(272);
          }

          .sa {
            right: vw(260);
            bottom: vh(200);
          }
        }

        .earth-content-desc {
          width: vw(750);
          margin-top: vh(20);

          .earth-content-desc-container {
            div {
              display: flex;
              justify-content: space-around;
            }

            p {
              min-width: 120px;
              width: vw(200);
              font-size: font(16);
              color: #ffffff;
              display: flex;
              align-items: center;

              span {
                font-size: font(24);
                margin: 0 vw(10);
              }

              &.square:before {
                display: inline-block;
                content: "";
                width: 17px;
                height: 17px;
                vertical-align: top;
                margin-right: vw(10);
              }

              &.as-bg:before {
                background: #61faf1;
              }

              &.af-bg:before {
                background: #0264f7;
              }

              &.eu-bg:before {
                background: #bf2ffb;
              }

              &.sa-bg:before {
                background: #6319fd;
              }

              &.na-bg:before {
                background: #d7fe62;
              }

              &.oc-bg:before {
                background: #39f859;
              }

              &.ws-bg:before {
                background: #7c4afb;
              }
            }
          }
        }
      }
    }

    .center-container {
      width: vw(2314);
      height: 100vh;

      .map-title {
        width: vw(348);
        height: vh(130);
        background: url("../../assets/largeScreen/square.png") no-repeat;
        background-size: vw(348) vh(130);
        margin-top: vh(34);
        color: #62e0f2;
        display: flex;
        font-size: font(26);
        line-height: vh(26);
        justify-content: center;
        align-items: center;

        span {
          display: inline-block;
          margin-bottom: vh(10);
        }
      }

      .map-container {
        .amap-marker-label {
          padding: 0;
          background: transparent;
          border: none;
        }

        height: vh(897);

        .map-box {
          width: vw(2314);
          height: vh(897);
        }
      }

      ::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #061855;
      }

      .company-list-container {
        margin-top: vh(30);
        width: vw(2314);
        // width: vw(3040);
        overflow-x: scroll;
        display: flex;

        .company-list-item {
          float: left;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: vw(15);
          margin-left: vw(15);

          &:hover {
            cursor: pointer;
          }

          .company-list-item-top {
            display: flex;
            align-items: center;
            justify-content: center;
            width: vw(160);
            height: vh(132);
            background: url("../../assets/largeScreen/company-icon-box.png") no-repeat;
            background-size: vw(160) vh(132);

            span {
              display: inline-block;
              font-size: font(36);
              color: #00eaff;
              margin-bottom: vh(36);
            }
          }

          p {
            margin-top: vh(12);
            font-size: font(30);
            color: #00eaff;
            width: vw(160);
            -webkit-line-clamp: 2; //显示2行
            overflow: hidden; //超出隐藏
            white-space: pre-wrap; //允许换行
            display: -webkit-box; //盒子模型
            -webkit-box-orient: vertical; //元素的排列方式
            text-overflow: ellipsis; //省略号显示超出部分
          }
        }
      }
    }

    .right-container {
      width: vw(993);
      height: 100vh;
      background: url("../../assets/largeScreen/right-bg.png") no-repeat;
      background-size: cover;
    }
  }

  .marker-info {
    width: vw(228);
    height: vh(171);
    background: url("../../assets/largeScreen/marker-card.png") no-repeat;
    background-size: vw(228) vh(171);

    h5 {
      font-weight: 700;
      color: #ffffff;
      font-size: font(14);
      padding: vh(9) vw(15);
    }

    img {
      width: vw(193);
      height: vh(110);
      padding: vh(10) vw(17);
    }
  }

  .scroll {
    width: vw(2300);
    margin: 0 auto;
    height: vh(210);
    overflow: hidden;
    //div{
    //  div:nth-of-type(2){
    //    display: none;
    //  }
    //}
  }
}

@media (max-width: 1920px) {
  .earth-content-ball {
    img {
      //width: 304px !important;
      height: 309px !important;
    }

    .ns {
      left: 60px !important;
      top: 100px !important;
    }

    .as {
      top: 130px !important;
      right: 60px !important;
    }

    .eu {
      top: 120px !important;
    }

    .af {
      bottom: 120px !important;
    }

    .oc {
      bottom: 100px !important;
    }

    .sa {
      bottom: 100px !important;
    }
  }

  .earth-map {
    img {
      //width: 367px !important;
      height: 312px !important;
    }

    .ns {
      top: 100px !important;
    }

    .as {
      top: 85px !important;
      right: 100px !important;
    }

    .eu {
      top: 50px !important;
      right: 30px !important;
    }

    .af {
      top: 165px !important;
      right: 100px !important;
    }

    .oc {
      right: 60px !important;
      bottom: 120px !important;
    }

    .ws {
      top: 160px !important;
      left: 150px !important;
    }

    .sa {
      top: 210px !important;
      right: 100px !important;
    }
  }

  .earth-content-desc-container {
    display: flex;
    justify-content: space-around;

    div {
      flex-direction: column !important;
    }

    p {
      line-height: 3;
    }
  }

  .ml {
    margin-left: 64px;
  }

  .company-list-item-top {
    height: 60px !important;
    background-size: contain !important;
  }

  .company-list-item .company-list-item-top span {
    margin-bottom: 20px !important;
  }
}
</style>
