import { render, staticRenderFns } from "./ysyVideoPlayer.vue?vue&type=template&id=be7df4d6"
import script from "./ysyVideoPlayer.vue?vue&type=script&lang=js"
export * from "./ysyVideoPlayer.vue?vue&type=script&lang=js"
import style0 from "./ysyVideoPlayer.vue?vue&type=style&index=0&id=be7df4d6&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be7df4d6')) {
      api.createRecord('be7df4d6', component.options)
    } else {
      api.reload('be7df4d6', component.options)
    }
    module.hot.accept("./ysyVideoPlayer.vue?vue&type=template&id=be7df4d6", function () {
      api.rerender('be7df4d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/safe-intelligent/ysyVideoPlayer.vue"
export default component.exports