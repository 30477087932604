<template>
  <div class="video">
    <video v-if="showVideo" class="videolist" :id="videoData.id" controls muted @click="screenProjection"></video>
    <!-- <img v-else :src="require('@/image/noVideo.png')"> -->
    <div v-else :id="eleId" class="videolist">
      <img class="image-logo" :src="require('@/assets/noVideo.png')" />
    </div>
  </div>
</template>

  <script>
const Hls = require("hls.js");
export default {
  props:{
    videoDataP4: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      pluginnamearr: "",
      eleId: `id-${Math.random().toString().slice(2)}`,
      hlsObj: [],
      hls: null,
      videoData: {id:''},
      playVideo: null,
      showVideo: false,
    };
  },
  mounted() {
    setTimeout(() => {
      // this.handleRealplay();
    }, 1000);
  },
  /* 页面销毁时  清除播放流 */
  destroyed() {
    if (this.hls) this.hls.destroy();
  },
  // 这里来调用不要写在 beforeUpdate
  watch: {
    videoData() {
      this.$nextTick(() => {
        setTimeout(() => {
          if(this.videoData.id){
            this.handleRealplay();
          }
        }, 300);
      });
    },
    videoDataP4(){
      this.videoData=this.videoDataP4
      this.showVideo= false
    }
  },
  methods: {
    handleRealplay() {
      // zhe下面的可能是2种不同的流播放
      const { eleId, wsUrlA } = this.videoData;
      const playVFn = () => {
        this.showVideo = true;
        this.$nextTick(() => {
          this.videoPlay();
        });
      };
      if (!wsUrlA) {
        playVFn();
        return;
      }
      const curIndex = 0; // 当前窗口下标
      this.player = new window.JSPlugin({
        szId: eleId, // 需要英文字母开头 必填
        szBasePath: "./", // 必填,引用H5player.min.js的js相对路径
        // 当容器div#play_window有固定宽高时，可不传iWidth和iHeight，窗口大小将自适应容器宽高
        // iWidth: 600,
        // iHeight: 400,
        iMaxSplit: 1, // 分屏播放，默认最大分屏4*4
        iCurrentSplit: 1,
        openDebug: true,
        oStyle: {
          borderSelect: "#FFCC00",// 样式
        },
      });
      // wsUrlA,流媒体播放时必传
      // mode: 0  解码类型：0=普通模式; 1=高级模式 默认为0
      // curIndex 当前窗口下标
      this.player.JS_Play(wsUrlA, { playURL: wsUrlA, mode: 0, }, curIndex).then(() => {
        console.log("realplay success");
      }, (e) => {
        console.error("播放错误", e);
        playVFn();
      });
    },

    videoPlay() {
      //  这句代码就是可以销毁 自己问了写
      let video = document.getElementById(this.videoData.id);
      let videoSrc = this.videoData.url;
      if (!videoSrc) {
        // this.$message.success("请选择摄像头播放：HLS");
        return;
      }
      if (Hls.isSupported()) {
        if (!this.hls) {
          this.hls = new Hls();
        }
        this.hls.loadSource(videoSrc);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }
    },

    screenProjection(){
      this.$emit('BigVideoData', this.videoData)
    }
  },
}
    </script>

  <style lang="scss" scoped>
.video {
  width: 100%;
  height: 100%;
  position: relative;
}
img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.videolist {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
