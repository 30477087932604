<template>
  <div
    id="player"
    class="playWnd"
    ref="player"
  ></div>
</template>

<script>
export default {
  name: 'hkPlayerWebControl',
  data () {
    return {
      oWebControl: null,
      initCount: 0,
      pubKey: ''
    }
  },
  mounted () {
    let _this = this
    window.addEventListener('resize', function () {
      if (_this.oWebControl != null) {
        console.log('this.$refs.player.offsetWidth', _this.$refs.player.offsetWidth)
        _this.oWebControl.JS_Resize(_this.$refs.player.offsetWidth, _this.$refs.player.offsetHeight)
        _this.setWndCover()
      }
    })
    // this.initPlugin()
  },
  destroyed () {
    if (this.oWebControl != null) {
      this.oWebControl.JS_HideWnd()// 先隐去窗口，规避插件窗口滞后于浏览器消失问题
      this.oWebControl.JS_Disconnect().then(function () {
        console.log('111断开与插件服务连接成功 ')
      }, function () {
        console.log('000断开与插件服务连接失败 ')
      })
    }
  },
  methods: {
    setWndCover () {
      var iWidth = window.innerWidth
      var iHeight =window.innerHeight
      var oDivRect = this.$refs.player.getBoundingClientRect()

      var iCoverLeft = (oDivRect.left < 0) ? Math.abs(oDivRect.left) : 0
      var iCoverTop = (oDivRect.top < 0) ? Math.abs(oDivRect.top) : 0
      var iCoverRight = (oDivRect.right - iWidth > 0) ? Math.round(oDivRect.right - iWidth) : 0
      var iCoverBottom = (oDivRect.bottom - iHeight > 0) ? Math.round(oDivRect.bottom - iHeight) : 0

      iCoverLeft = (iCoverLeft > 1000) ? 1000 : iCoverLeft
      iCoverTop = (iCoverTop > 600) ? 600 : iCoverTop
      iCoverRight = (iCoverRight > 1000) ? 1000 : iCoverRight
      iCoverBottom = (iCoverBottom > 600) ? 600 : iCoverBottom
      this.oWebControl.JS_RepairPartWindow(0, 0, 1001, 600)    // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft != 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, 600)
      }
      if (iCoverTop != 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 0, 1001, iCoverTop)    // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight != 0) {
        this.oWebControl.JS_CuttingPartWindow(1000 - iCoverRight, 0, iCoverRight, 600)
      }
      if (iCoverBottom != 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 600 - iCoverBottom, 1000, iCoverBottom)
      }
    },
    /**
     * 实例初始化
     */
    initPlugin (option) {
      const that = this
      this.oWebControl = new WebControl({
        szPluginContainer: 'player',                       // 指定容器id
        iServicePortStart: 15900,                           // 指定起止端口号，建议使用该值
        iServicePortEnd: 15900,
        szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11',   // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function () {
          console.log('2.cbConnectSuccess')
          // 创建WebControl实例成功
          that.oWebControl.JS_StartService('window', {         // WebControl实例创建成功后需要启动服务
            dllPath: './VideoPluginConnect.dll'         // 值"./VideoPluginConnect.dll"写死
          }).then(function () {
            console.log('3.WebControl实例创建成功后启动服务')// 启动插件服务成功
            that.oWebControl.JS_SetWindowControlCallback({   // 设置消息回调
              cbIntegrationCallBack: that.cbIntegrationCallBack
            })
            that.oWebControl.JS_CreateWnd('player', that.$refs.player.offsetWidth, that.$refs.player.offsetHeight).then(function () { //JS_CreateWnd创建视频播放窗口，宽高可设定
              that.init(option)  // 创建播放实例成功后初始化
            })
          }, function (err) { // 启动插件服务失败

          })
        },
        cbConnectError: function () { // 创建WebControl实例失败
          that.oWebControl = null
          console.log('插件未启动，正在尝试启动，请稍候...')
          WebControl.JS_WakeUp('VideoWebPlugin://') // 程序未启动时执行error函数，采用wakeup来启动程序
          that.initCount++
          if (that.initCount < 3) {
            setTimeout(function () {
              that.initPlugin()
            }, 3000)
          } else {
            console.log('插件启动失败，请检查插件是否安装！')
          }
        },
        cbConnectClose: function (bNormalClose) {
          console.log(bNormalClose)
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log('cbConnectClose')
          that.oWebControl = null
          if (bNormalClose) {
            return
          }
          console.log('插件未启动，正在尝试启动，请稍候...')
          WebControl.JS_WakeUp('VideoWebPlugin://')
          that.initCount++
          if (that.initCount < 3 && !bNormalClose) {
            setTimeout(function () {
              that.initPlugin()
            }, 3000)
          } else {
            that.$notify({
              duration: 0,
              dangerouslyUseHTMLString: true,
              position: 'top-left',
              customClass:'VideoWebPlugin',
              message: '插件启动失败，<a style="text-decoration: revert;color: #409eff;" href="https://hse.sinoma.com.cn:8000/webPlugin/VideoWebPlugin.exe">点击安装</a><p style="cursor: pointer;" onclick="location.reload()">如果已经安装，<a style="color: #409eff;text-decoration: underline;">点击刷新</a></p>'
            });
            console.log('插件启动失败，请检查插件是否安装！')
          }
        }
      })
    },
    init (option={}) {
      let _this = this
      this.getPubKey(function () {
        console.log('4.创建播放实例成功后初始化')
        ////////////////////////////////// 请自行修改以下变量值	////////////////////////////////////
        var appkey = sessionStorage.getItem('appKey')                           //综合安防管理平台提供的appkey，必填
        var secret = _this.setEncrypt(sessionStorage.getItem('appSecret'))   //综合安防管理平台提供的secret，必填
        var ip = sessionStorage.getItem('host')
        // TODO：上面三个参数是动态的，后面的 port可能也会调整
        //综合安防管理平台IP地址，必填
        var playMode = 0                                  //初始播放模式：0-预览，1-回放
        var port = 443                                    //综合安防管理平台端口，若启用HTTPS协议，默认443
        var snapDir = 'D:\\SnapDir'                       //抓图存储路径
        var videoDir = 'D:\\VideoDir'                     //紧急录像或录像剪辑存储路径
        var layout = '2x2'                                //playMode指定模式的布局
        var enableHTTPS = 1                               //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
        var encryptedFields = 'secret'					   //加密字段，默认加密领域为secret
        var showToolbar = 0                               //是否显示工具栏，0-不显示，非0-显示
        var showSmart = 1                                 //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
        var buttonIDs = '0,16,256,257,258,259,260,512,513,514,515,516,517,768,769'  //自定义工具条按钮
        ////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

        _this.oWebControl.JS_RequestInterface({
          funcName: 'init',
          argument: JSON.stringify({
            appkey: appkey,                            //API网关提供的appkey
            secret: secret,                            //API网关提供的secret
            ip: ip,                                    //API网关IP地址
            playMode: playMode,                        //播放模式（决定显示预览还是回放界面）
            port: port,                                //端口
            snapDir: snapDir,                          //抓图存储路径
            videoDir: videoDir,                        //紧急录像或录像剪辑存储路径
            layout: layout,                            //布局
            enableHTTPS: enableHTTPS,                  //是否启用HTTPS协议
            encryptedFields: encryptedFields,          //加密字段
            showToolbar: showToolbar,                  //是否显示工具栏
            showSmart: showSmart,                      //是否显示智能信息
            buttonIDs: buttonIDs,                       //自定义工具条按钮
                ...option,
          })
        }).then(function (oData) {
          console.log('5._this.oWebControl', _this.oWebControl)
          _this.oWebControl.JS_Resize(_this.$refs.player.offsetWidth, _this.$refs.player.offsetHeight)  // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
          _this.$emit('oWebControl')
        })
      })
    },
    getPubKey (callback) {
      let _this = this
      this.oWebControl.JS_RequestInterface({
        funcName: 'getRSAPubKey',
        argument: JSON.stringify({
          keyLength: 1024
        })
      }).then(function (oData) {
        console.log('oData', oData)
        if (oData.responseMsg.data) {
          _this.pubKey = oData.responseMsg.data
          callback()
        }
      })
    },
    setEncrypt (value) {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(this.pubKey)
      return encrypt.encrypt(value)
    },
    //视频预览功能
    playVideo (cameraIndexCode, wndId = -1) {
      // var cameraIndexCode  = $("#cameraIndexCode").val();     //获取输入的监控点编号值，必填
      var streamMode = 1                                     //主子码流标识：0-主码流，1-子码流
      var transMode = 1                                      //传输协议：0-UDP，1-TCP
      var gpuMode = 0                                        //是否启用GPU硬解，0-不启用，1-启用
      // var wndId = -1;                                         //播放窗口序号（在2x2以上布局下可指定播放窗口）

      cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, '')
      cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, '')
      this.oWebControl?.JS_RequestInterface({
        funcName: 'startPreview',
        argument: JSON.stringify({
          cameraIndexCode: cameraIndexCode,                //监控点编号
          streamMode: streamMode,                         //主子码流标识
          transMode: transMode,                           //传输协议
          gpuMode: gpuMode,                               //是否开启GPU硬解
          wndId: wndId                                     //可指定播放窗口
        })
      })
    },
    cbIntegrationCallBack (oData) {
      console.log('ERROR:oData', oData)
      // showCBInfo(JSON.stringify(oData.responseMsg));
    }
  },
}
</script>

<style lang="scss" >
.playWnd {
  //margin: 10px;
  // width: vw(3060); /*播放容器的宽和高设定*/
  width: 100%;
  // height: vh(1060);
  height: 100%;
}
.VideoWebPlugin{
  left: 16.2790697674vw !important;
}
</style>
