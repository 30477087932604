<template>
  <div class="project-map flex-container">
    <section class="left-section">
      <div class="flex-container flex-align-c">
        <span class="el-icon-arrow-left back-btn" @click="backScreen"></span>
        <span class="header-title">中材国际智慧安全监控大屏</span>
      </div>
      <div class="project-container">
        <div class="card">
          <div class="card-title">
            <span class="card-title-name">国内项目</span>
            <span class="card-title-quantity">{{ innerCountry.length }}个</span>
          </div>
          <div class="card-content">
            <div :class="['card-content-item', chooseItem.id === item.id ? 'active' : '']" @click="selProjectItem(item)"
              v-for="(item, index) in innerCountry" :key="index">
              <div :title="item.name" :class="['card-content-item-title', item.cameraNum > 0 ? 'online' : 'offline']">
                {{ item.name }}
              </div>
              <div :class="item.cameraNum > 0 ? 'card-content-item__online' : 'card-content-item__offline'"></div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <span class="card-title-name">国外项目</span>
            <span class="card-title-quantity">{{ outerCountry.length }}个</span>
          </div>
          <div class="card-content">
            <div :class="['card-content-item', chooseItem.id === item1.id ? 'active' : '']"
              v-for="(item1, index1) in outerCountry" @click="selProjectItem(item1)" :key="index1">
              <div :title="item1.name" :class="['card-content-item-title', item1.cameraNum > 0 ? 'online' : 'offline']">
                {{ item1.name }}
              </div>
              <div :class="item1.cameraNum > 0 ? 'card-content-item__online' : 'card-content-item__offline'"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="right-section">
      <div v-show="chooseItem.id" class="camera-list-container">
        <div class="camera-list">
          <h5>摄像头列表</h5>
        </div>
        <div class="video-name infinite-list" v-infinite-scroll="load" style="overflow: auto">
          <div v-for="(item, i) in videoNameList" :key="i" class="video-name-list_item infinite-list-item">
            <div @mouseenter="enterName(i, item.status, item)" @mouseleave="leaveName" class="video-mouse-H">
              <div class="video-name-list_item-btn" v-show="showVideoBtn && i == indCur">
                <button type="success" @click="video_P1(item)" class="btn-p">p1</button>
                <button type="success" @click="video_P2(item)" class="btn-p">p2</button>
                <button type="success" @click="video_P3(item)" class="btn-p">p3</button>
                <button type="success" @click="video_P4(item)" class="btn-p">p4</button>
              </div>
              <span style="color: green; margin-right: 4px" v-show="item.selP" class="item_name_1">{{
          item.selP
        }}</span>
              <span :title="item.cameraName" class="item_name_1">{{ item.cameraName }}</span>
              <!-- <span :title="item.cameraName" v-show="item.status===2" class="item_name_2">{{ item.cameraName }}</span> -->
              <!-- <span v-show="item.status===1" class="item_status_1">● 在线</span> -->
              <!-- <span v-show="item.status===2" class="item_status_2">● 离线</span> -->
            </div>
          </div>
          <div v-show="isEnd" class="video-name-list_item infinite-list-item">
            <div class="video-mouse-H justify-content">
              <div class="video-name-list_item-btn">
                <div class="item_name_1">到底了~~</div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="contact-img">-->
        <!--          <img src="../../image/largeScreen/contact.png" alt="联系现场">-->
        <!--        </div>-->
      </div>
      <div class="big-video">
        <VideoOnePro :BigVideoData="BigVideoData"></VideoOnePro>
      </div>
      <div v-show="chooseItem.id" class="small-video">
        <div class="top-video">
          <!--          <div class="video-title">视频监控列表</div>-->
          <hk-player-web-control v-if="videoS_TypeObj.sysType === 0" ref="HkPlayerWebControl"
            @oWebControl="oWebControl"></hk-player-web-control>
          <!--                    <y-shi-yun-player-web-control-->
          <!--                      v-if="videoS_TypeObj.sysType == 1"-->
          <!--                      @oWebControl="oWebControl"-->
          <!--                      ref="yShiYunPlayerWebControl">-->
          <!--                    </y-shi-yun-player-web-control>-->
          <ysy-video-player v-if="videoS_TypeObj.sysType === 1" ref="ysyVideoPlayer"
            @oWebControl="oWebControl"></ysy-video-player>
          <!--          <ul class="video-list" v-if="videoS_TypeObj.sysType == 0">-->
          <!--            <li>-->
          <!--              <VideoA :videoDataP1="videoDataP1" @BigVideoData="clickBigVideoData"></VideoA>-->
          <!--            </li>-->
          <!--            <li>-->
          <!--              <VideoB :videoDataP2="videoDataP2" @BigVideoData="clickBigVideoData"></VideoB>-->
          <!--            </li>-->
          <!--            <li>-->
          <!--              <VideoC :videoDataP3="videoDataP3" @BigVideoData="clickBigVideoData"></VideoC>-->
          <!--            </li>-->
          <!--            <li>-->
          <!--              <VideoD :videoDataP4="videoDataP4" @BigVideoData="clickBigVideoData"></VideoD>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
      </div>
      <div id="project-map-container"></div>
    </section>
  </div>
</template>

<script>
import VideoOnePro from "@/views/large-screen/videoPage/videoOnePro.vue";
import VideoA from "@/views/large-screen/videoPage/videoA.vue";
import VideoB from "@/views/large-screen/videoPage/videoB.vue";
import VideoC from "@/views/large-screen/videoPage/videoC.vue";
import VideoD from "@/views/large-screen/videoPage/videoD.vue";
import { $_getComList } from "@/api/systemConfiguration/securityConference";
import {
  getProjectConfigDeviceList,
} from "@/api/projectManagement/projectManagement";
import HkPlayerWebControl from "@/components/safe-intelligent/hkPlayerWebControl.vue";
import YsyVideoPlayer from "@/components/safe-intelligent/ysyVideoPlayer.vue";

export default {
  name: "projectMap",
  components: {
    YsyVideoPlayer,
    HkPlayerWebControl,
    VideoA,
    VideoB,
    VideoC,
    VideoD,
    VideoOnePro,
  },
  mounted() {
    this.initPage();
  },
  data() {
    return {
      map: null,
      chooseItem: {},
      innerCountry: [],
      outerCountry: [],
      sts: [
        {
          url: require("../../assets/largeScreen/ten-cluster.png"),
          size: new AMap.Size(178, 178),
          textColor: "#FFFDCA",
          textSize: 36,
        },
        {
          url: require("../../assets/largeScreen/hundred-cluster.png"),
          size: new AMap.Size(178, 178),
          textColor: "#FFFDCA",
          textSize: 30,
        },
        {
          url: require("../../assets/largeScreen/thousand-cluster.png"),
          size: new AMap.Size(178, 178),
          textColor: "#FFFDCA",
          textSize: 30,
        },
        {
          url: require("../../assets/largeScreen/ten-cluster.png"),
          size: new AMap.Size(178, 178),
          textColor: "#FFFDCA",
          textSize: 30,
        },
        {
          url: require("../../assets/largeScreen/ten-cluster.png"),
          size: new AMap.Size(178, 178),
          textColor: "#FFFDCA",
          textSize: 30,
        },
      ],
      form: {
        name: "测试",
      },
      // 1：选中，1：在线
      videoNameList: [
        // { status: 1, url: 'https://open.ys7.com/v3/openlive/233619455_10_1.m3…cafc524e6e9d83e8f43985a35f5dadbd7da95e0122&ev=100', name: 'KST摄像头名称', checkBox: 0, id: 1 },
        // { status: 2, url: 'http://47.94.38.138:83/openUrl/hhlwWd2/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 2 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/hh5lNNm/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 3 },
        // { status: 2, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 4 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 5 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 6 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 7 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 8 },
        // { status: 2, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 9 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 10 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 11 },
        // { status: 1, url: 'http://47.94.38.138:83/openUrl/pMW9LxK/live.m3u8', name: 'KST摄像头名称', checkBox: 0, id: 12 },
      ],
      showVideoBtn: false,
      indCur: null,
      current: 1,
      total: 0,
      size: 15,
      isEnd: false, //摄像头已经是最低部了
      videoDataP1: {}, //视频的数据
      videoDataP2: {}, //视频的数据
      videoDataP3: {}, //视频的数据
      videoDataP4: {}, //视频的数据
      BigVideoData: {}, //  中间放大版的监控
      videoS_TypeObj: {},
    };
  },
  methods: {
    clearSelP(numP) {
      this.videoNameList.map((i) => {
        if (i.selP === numP) {
          i.selP = "";
        }
      });
    },
    oWebControl() {
      console.log("last step: oWebControl装载完毕.");
      if (this.videoS_TypeObj.sysType === 0) {
        this.videoNameList.map((item, index) => {
          if (index < 4) {
            item.selP = index + 1 + "P";
            this.$refs.HkPlayerWebControl.playVideo(item.cameraCode, index + 1);
          }
        });
      } else {
        this.videoNameList.map((item, index) => {
          if (index < 4) {
            item.selP = index + 1 + "P";
            // setTimeout(()=>{
            //   this.$refs.yShiYunPlayerWebControl.changeWndAndPlay(index,item.cameraCode,item.channelNo);
            // },1000)
            this.$refs.ysyVideoPlayer.play(index, item);
          }
        });
      }
    },
    /* 小监控屏幕传来的数据给大屏幕赋值 */
    clickBigVideoData(BigVideoData) {
      let video = document.querySelector(".big-video");
      video.className = "big-video big_h";
      this.$nextTick(() => {
        this.BigVideoData = BigVideoData;
      });
    },
    /**
     * 地图初始化
     */
    initMap(data) {
      // 1. 构造地图数据
      data.map((j) => {
        j.lnglat = (j.longitudeLatitude && j.longitudeLatitude.split(",")) || [125.597307, 47.187223];
      });
      // 2. 构建地图实例
      if (this.map) {
        this.map.destroy();
      }
      this.map = new AMap.Map("project-map-container", {
        center: [104.937478, 35.439575],
        showLabel: false,
        mapStyle: "amap://styles/503006feca46ab82417777eada0c01ae",
        zoom: 5,
      });
      let gridSize = 180;
      let cluster = "";
      // 3. 自定义 marker渲染方法
      let _renderMarker = function (context) {
        let content = `<div>
<img src=${require("../../assets/largeScreen/single-marker.png")} alt="单点标记" class="marker-class">
<p class="marker-name">${context.data[0].name}</p>
</div>`;
        let offset = new AMap.Pixel(-9, -9);
        context.marker.setContent(content);
        context.marker.setOffset(offset);
      };
      // 4. 聚合功能
      cluster = new AMap.MarkerCluster(this.map, data, {
        gridSize: gridSize, // 设置网格像素大小
        styles: this.sts,
        renderMarker: _renderMarker, // 自定义非聚合点样式
      });
      // 5. 聚合点击事件
      cluster.on("click", (item) => {
        //此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
        if (item.clusterData.length <= 1) {
          return;
        }
        //这里是计算所有聚合点的中心点
        let alllng = 0,
          alllat = 0;
        for (const mo of item.clusterData) {
          alllng += mo.lnglat.lng;
          alllat += mo.lnglat.lat;
        }
        const lat = alllat / item.clusterData.length;
        const lng = alllng / item.clusterData.length;
        //这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
        this.map.setZoomAndCenter(this.map.getZoom() + 2, [lng, lat]);
      });
    },
    //移入显示选择视频区
    enterName(index, status, item) {
      this.showVideoBtn = true;
      this.indCur = index;
      if (status === 2 || item.checkBox) {
        this.showVideoBtn = false;
      }
    },
    //移出显示选择视频区
    leaveName() {
      this.showVideoBtn = false;
      this.indCur = null;
    },
    /* 摄像头 P1 按钮 */
    video_P1(item) {
      if (this.videoS_TypeObj.sysType === 0) {
        this.clearSelP("1P");
        item.selP = "1P";
        this.$refs.HkPlayerWebControl.playVideo(item.cameraCode, 1);
      } else {
        this.clearSelP("1P");
        // if (item.selP) {
        //   this.$refs.yShiYunPlayerWebControl.stopPlay(parseInt(item.selP) - 1);
        // }
        item.selP = "1P";
        // await this.$refs.yShiYunPlayerWebControl.SelectPlayWnd( 0,item.cameraCode,item.channelNo);
        // await this.$refs.yShiYunPlayerWebControl.startPreview( 0,item.cameraCode,item.channelNo);
        // setTimeout(()=>{
        //   this.$refs.yShiYunPlayerWebControl.changeWndAndPlay( 0,item.cameraCode,item.channelNo)
        // },500)
        this.$refs.ysyVideoPlayer.stopAndPlay(0, item);
      }
    },
    /* 摄像头 P2 按钮 */
    video_P2(item) {
      if (this.videoS_TypeObj.sysType === 0) {
        this.clearSelP("2P");
        item.selP = "2P";
        this.$refs.HkPlayerWebControl.playVideo(item.cameraCode, 2);
      } else {
        this.clearSelP("2P");
        // if (item.selP) {
        //   this.$refs.yShiYunPlayerWebControl.stopPlay(parseInt(item.selP) - 1);
        // }
        item.selP = "2P";
        // await this.$refs.yShiYunPlayerWebControl.SelectPlayWnd(1,item.cameraCode, item.channelNo);
        // await this.$refs.yShiYunPlayerWebControl.startPreview( 1,item.cameraCode,item.channelNo);
        // setTimeout(()=>{
        //   this.$refs.yShiYunPlayerWebControl.changeWndAndPlay( 1,item.cameraCode,item.channelNo)
        // },500)
        this.$refs.ysyVideoPlayer.stopAndPlay(1, item);
      }
    },
    /* 摄像头 P3 按钮 */
    video_P3(item) {
      if (this.videoS_TypeObj.sysType === 0) {
        this.clearSelP("3P");
        item.selP = "3P";
        this.$refs.HkPlayerWebControl.playVideo(item.cameraCode, 3);
      } else {
        this.clearSelP("3P");
        // if (item.selP) {
        //   this.$refs.yShiYunPlayerWebControl.stopPlay(parseInt(item.selP) - 1);
        // }
        item.selP = "3P";
        // await this.$refs.yShiYunPlayerWebControl.SelectPlayWnd(2,item.cameraCode, item.channelNo);
        // await this.$refs.yShiYunPlayerWebControl.startPreview( 2,item.cameraCode,item.channelNo);
        // setTimeout(()=>{
        //   this.$refs.yShiYunPlayerWebControl.changeWndAndPlay( 2,item.cameraCode,item.channelNo)
        // },500)
        this.$refs.ysyVideoPlayer.stopAndPlay(2, item);
      }
    },
    /* 摄像头 P4 按钮 */
    video_P4(item) {
      if (this.videoS_TypeObj.sysType === 0) {
        this.clearSelP("4P");
        item.selP = "4P";
        this.$refs.HkPlayerWebControl.playVideo(item.cameraCode, 4);
      } else {
        this.clearSelP("4P");
        // if (item.selP) {
        //   this.$refs.yShiYunPlayerWebControl.stopPlay(parseInt(item.selP) - 1, item.cameraCode, item.channelNo);
        // }
        item.selP = "4P";
        //  await this.$refs.yShiYunPlayerWebControl.SelectPlayWnd(3,item.cameraCode,item.channelNo);
        // await this.$refs.yShiYunPlayerWebControl.startPreview( 3,item.cameraCode,item.channelNo);
        // setTimeout(()=>{
        //   this.$refs.yShiYunPlayerWebControl.changeWndAndPlay( 3,item.cameraCode,item.channelNo)
        // },500)
        this.$refs.ysyVideoPlayer.stopAndPlay(3, item);
      }
    },
    /**
     * 页面数据初始化
     */
    initPage() {
      // 获取公司下面的项目数据
      $_getComList({ parentId: sessionStorage.getItem("parentId") }).then((res) => {
        // 拆分国内项目
        this.innerCountry = res?.data?.data.filter((i) => i.projectType == 1) || [];
        // 拆分国外项目
        this.outerCountry = res?.data?.data.filter((i) => i.projectType == 2 || i.projectType === "") || [];
        // 根据项目数据，利用坐标显示地图
        this.initMap(res.data.data);
      });
    },
    /* 懒加载项目中的视频 */
    load() {
      if (this.total / this.size > this.current) {
        this.current += 1;
        const { current } = this;
        // 2. 获取绑定摄像头列表
        getProjectConfigDeviceList({ size: 15, current, deptID: this.chooseItem.id }).then((res) => {
          if (res.data.code === 200 && res.data.data.records.length >= 1)
            // TODO：得到摄像头的列表信息，展示摄像头列表
            this.videoNameList = res.data.data.records;
        });
      } else {
        this.$nextTick(() => {
          this.isEnd = true;
        });
      }
    },
    /**
     * 选择项目
     */
    selProjectItem(item) {
      // 切换前对视频插件进行销毁
      if (this.$refs?.HkPlayerWebControl?.oWebControl) {
        this.$refs.HkPlayerWebControl.oWebControl?.JS_HideWnd();
        this.$refs.HkPlayerWebControl.oWebControl?.JS_DestroyWnd();
      }
      if (this.$refs.ysyVideoPlayer) {
        this.$refs.ysyVideoPlayer.stopAll();
        // this.$refs.ysyVideoPlayer.destroyAll();
        this.$nextTick(() => {
          this.$refs.ysyVideoPlayer.videoShow = false;
        });
      }
      // if (this.$refs.yShiYunPlayerWebControl?.oWebControl){
      //   this.$refs.yShiYunPlayerWebControl.hideVideo()
      //   this.$refs.yShiYunPlayerWebControl.WebControlDistory()
      // }
      this.videoDataP1 = { id: "" };
      this.videoDataP2 = { id: "" };
      this.videoDataP3 = { id: "" };
      this.videoDataP4 = { id: "" };
      this.BigVideoData = { id: "" };
      let video = document.querySelector(".big-video");
      video.className = "big-video";
      // 地图重设中心点
      this.map.setZoomAndCenter(this.map.getZoom() < 7 ? this.map.getZoom() + 2 : 7, item.lnglat);
      // 项目选中样式
      this.chooseItem = item;
      // 1. 没有绑定摄像头就不需要显示摄像头列表,但是要注意项目信息应该还是要展示出来
      if (item.cameraNum === 0) {
        this.videoNameList = [];
        return;
      }
      this.current = 1;
      const { current, size } = this;
      // 2. 获取绑定摄像头列表
      getProjectConfigDeviceList({ size, current, deptId: item.id }).then((res) => {
        this.total = res.data.data.total;
        this.videoNameList = res.data.data.records;
        if (this.videoNameList.length > 0) {
          this.videoS_TypeObj = this.videoNameList[0]["videoSystem"];
          sessionStorage.setItem("appKey", this.videoS_TypeObj.appKey);
          sessionStorage.setItem("appToken", this.videoNameList[0].token);
          sessionStorage.setItem("appSecret", this.videoS_TypeObj.appSecret);
          sessionStorage.setItem("host", this.videoS_TypeObj.host.slice(0, this.videoS_TypeObj.host.lastIndexOf(":")));
          if (this.videoS_TypeObj.sysType === 0) {
            this.$nextTick(() => {
              console.log(this.videoS_TypeObj.sysType);
              console.log(this.$refs);
              this.$refs.HkPlayerWebControl.initPlugin();
            });
          }
          if (this.videoS_TypeObj.sysType === 1) {
            // setTimeout(()=>{
            //   this.$refs.yShiYunPlayerWebControl.initPlugin();
            // },500)
            this.$nextTick(() => {
              this.$refs.ysyVideoPlayer.initPlugin();
              this.$refs.ysyVideoPlayer.videoShow = true;
            });
          }
        }
      });
    },

    /**
     * 返回按钮
     */
    backScreen() {
      if (this.videoS_TypeObj.sysType === 0) {
        this.$refs.HkPlayerWebControl.oWebControl?.JS_HideWnd();
        this.$refs.HkPlayerWebControl.oWebControl?.JS_DestroyWnd();
      }
      if (this.videoS_TypeObj.sysType === 1) {
        // this.$refs.yShiYunPlayerWebControl.hideVideo()
        // this.$refs.yShiYunPlayerWebControl.WebControlDistory()
        this.$refs.ysyVideoPlayer.stopAll();
      }
      this.$emit("backScreen", false);
    },
  },
};
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.project-map {
  .left-section {
    width: vw(700);
    height: vh(1290);
    margin-left: vw(40);

    .back-btn {
      display: flex;
      color: #74e9f7;
      font-size: font(52);
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

    .header-title {
      padding-top: 0;
    }

    .project-container {
      .card {
        &:nth-of-type(1) {
          margin-top: vh(30);
        }

        width: vw(670);
        height: vh(490);
        background: url("../../assets/largeScreen/card-bg.png") no-repeat;
        background-size: vw(670) vh(490);
        margin-bottom: vh(80);

        .card-title {
          display: flex;
          justify-content: space-between;

          .card-title-name {
            letter-spacing: vw(6);
            font-size: font(28);
            font-weight: 700;
            color: #ffffff;
            margin-left: vw(80);
          }

          .card-title-quantity {
            font-size: font(28);
            font-weight: 500;
            color: #03ddf4;
            margin-right: vw(20);
          }
        }

        .card-content {
          display: flex;
          flex-direction: column;
          padding: 0 vw(21);
          margin: vh(42) 0;
          max-height: vh(410);
          overflow-y: scroll;

          .card-content-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #ffffff;
            padding: vh(18) vw(21);
            position: relative;

            &:hover {
              cursor: pointer;
            }

            &.active {
              background: rgba(0, 122, 255, 0.3);
              border: 2px solid #007aff;
            }

            .card-content-item-title {
              font-size: font(24);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &.online {
                color: #ffffff;

                &:before {
                  width: vw(21);
                  height: vh(24);
                  display: inline-block;
                  content: "";
                  background: url("../../assets/largeScreen/online-camera.png") no-repeat;
                  background-size: vw(21) vh(24);
                  margin-right: vw(20);
                }
              }

              &.offline {
                color: #aaaaaa;

                &:before {
                  width: vw(21);
                  height: vh(24);
                  display: inline-block;
                  content: "";
                  background: url("../../assets/largeScreen/offline-camera.png") no-repeat;
                  background-size: vw(21) vh(24);
                  margin-right: vw(20);
                }
              }
            }

            .card-content-item__online {
              width: vw(12);
              height: vh(12);
              background: #15db6d;
              border-radius: 50%;
              position: absolute;
              right: 0;
            }

            .card-content-item__offline {
              width: vw(12);
              height: vh(12);
              background: #aaaaaa;
              border-radius: 50%;
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }

  .right-section {
    width: vw(3560);
    height: vh(1290);
    position: relative;

    .camera-list-container {
      width: vw(401);
      // height: vh(1055);
      height: 100%;
      //display: flex;
      //flex-direction: column;
      //justify-content: space-between;
      // background: url("../../assets/largeScreen/cameralist-bg.png") no-repeat;
      background-color: #083863;
      background-size: vw(401) vh(1055);
      position: absolute;
      top: 0;
      left: vw(15);
      z-index: 2;

      .camera-list {
        h5 {
          // font-size: font(20);
          color: #ffffff;
          font-weight: 500;
          margin-left: vw(43);
          margin-top: vh(16);
        }
      }

      .video-name {
        //height: vh(200);
        overflow-x: hidden;
        overflow-y: scroll;
        // margin-top: vh(28);
        background-color: #083863;

        // width: vh(880);
        .video-name-list_item {
          margin-top: vh(5);
          padding: 0 vw(20);
          background-color: rgba(255, 255, 255, 0.041);

          // background: #e0dede3f;
          // opacity: 0.1;
          .video-mouse-H {
            position: relative;
            height: vh(56);
            display: flex;
            //justify-content: space-between;
            font-size: vh(16);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            .item_status_1 {
              color: #15db6d;
              display: inline-block;
              height: vh(56);
              line-height: vh(56);
            }

            .item_status_2 {
              color: #aaa;
              display: inline-block;
              height: vh(56);
              line-height: vh(56);
            }

            .item_name_1 {
              color: #fff;
              display: inline-block;
              height: vh(56);
              line-height: vh(56);
            }

            .item_name_2 {
              color: #aaa;
              display: inline-block;
              height: vh(56);
              line-height: vh(56);
            }

            .video-name-list_item-btn {
              display: flex;
              // position: absolute;
              // top: 0;
              // left: 0;
              height: 100%;

              button {
                color: #ffffff;
                border: 0;
                background: rgba(0, 122, 255, 0.7);
                padding: 0 vw(10);
                margin-left: vw(10);
                font-size: vw(18);
                font-weight: 700;
              }
            }
          }

          .justify-content {
            justify-content: center;
          }
        }
      }

      .contact-img {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: vh(30);
        width: vw(401);

        img {
          width: vw(342);
          height: vh(62);
        }
      }
    }

    .big-video {
      width: vw(1737);
      height: 0;
      position: absolute;
      top: vh(90);
      left: vw(471);
      z-index: 2;
    }

    .big_h {
      height: vh(1055) !important;
    }

    .small-video {
      position: absolute;
      top: 0;
      width: calc(100% - vw(450));
      // top: vh(80);
      //left: vw(2248);
      left: vw(450);
      z-index: 2;
      height: 100%;

      .top-video {
        //background-image: url("../../image/videoBox.png");
        //background-size: vw(1250) vh(700);
        //background-repeat: no-repeat;
        //width: vw(1250);
        //height: vh(700);
        height: 100%;

        ul,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .video-title {
          padding-left: vw(45);
          line-height: vh(57);
          color: #fff;
          font-size: vh(18);
        }

        .video-list {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;

          li {
            // width: vw(600);
            // height: vh(300);
            margin-bottom: vh(25);
            background: #000;
            opacity: 0.8;
            width: 48%;
            height: 48%;
          }
        }
      }

      .bottom-text {
        margin-top: vh(30);
        width: vw(1250);
        height: vh(325);
        background-image: url("../../assets/formBox.png");
        background-size: vh(1250) vh(325);
        background-repeat: no-repeat;

        .video-title {
          padding-left: vw(45);
          line-height: vh(57);
          color: #fff;
          font-size: font(18);
        }

        .el-col {
          line-height: vh(60);
          display: flex;

          label {
            padding-left: vw(40);
            margin-right: vw(10);
            min-width: vw(100);
            font-size: vh(18);
            text-align: end;
            color: #bbc6d9;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span {
            font-size: vh(18);
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .el-col>span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .green-style {
          color: #15db6d !important;
        }
      }
    }

    #project-map-container {
      //height: vh(1060);
      height: 100%;
      // margin-top: vh(82);
      width: vw(3610);
    }
  }
}

::-webkit-scrollbar {
  //width: 0;
  //background-color: transparent;
}

.marker-class {
  width: vw(84);
  height: vh(136);
}

.marker-name {
  color: #03ddf4;
  font-size: font(22);
  font-weight: 500;
  margin: 4px 0 0 0;
  width: 30vw;
}
</style>
